import { render, staticRenderFns } from "./AlphabeticallyOrderedItems.vue?vue&type=template&id=61023d27&"
import script from "./AlphabeticallyOrderedItems.vue?vue&type=script&lang=ts&"
export * from "./AlphabeticallyOrderedItems.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VDivider,VLazy,VSubheader})
